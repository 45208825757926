import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useState } from 'react';
function ButtonMore(props) {
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleMenuVisibility = () => {
    setToggleMenu(toggleMenu ? false : true);
  };
  return (
    <div className="more-icon" role="button">
      <div className="dropdown">
        <FontAwesomeIcon
          icon="fas fa-ellipsis-v"
          size="lg"
          onClick={toggleMenuVisibility}
        />
        <div
          className="dropdown-content rounded"
          style={{ display: !toggleMenu ? 'none' : 'flex' }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default memo(ButtonMore);
