import React, { useState } from 'react';

import { useMediaContext } from '../../hooks/useMedia';
import { deleteMedia } from '../../services/fileUploadService';
import MediaItem from './MediaItem';

function Media() {
  const { medias, setMedias } = useMediaContext();
  const [isLoading, setLoading] = useState(false);

  const setTime = () => {
    return new Promise((resolve) => setTimeout(resolve, 500));
  };

  const onDeleteFile = async (id) => {
    try {
      if (!isLoading) {
        setLoading(true);
        await deleteMedia(id);
      }
      setTime().then(() => {
        setLoading(false);
      });
      setMedias(medias.filter((media) => media.id !== id));
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <div className="h-100">
      {medias &&
        medias.length > 0 &&
        medias.map((media) => {
          return (
            <MediaItem key={media.id} media={media} onDelete={onDeleteFile} />
          );
        })}
    </div>
  );
}
export default Media;
