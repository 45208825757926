import './index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { useMediaContext } from '../../hooks/useMedia';
import { createMedia, uploadFile } from '../../services/fileUploadService';

const UploadFiles = ({ interview, inline = false }) => {
  const { medias, setMedias } = useMediaContext();
  const [isLoading, setLoading] = useState(false);

  const setTime = () => {
    return new Promise((resolve) => setTimeout(resolve, 500));
  };

  const onFileUpload = async (event) => {
    event.preventDefault();
    const input = event.target;
    const selectedFile = input.files[0];
    try {
      if (!selectedFile) return null;
      const { url } = await uploadFile(selectedFile);
      if (!isLoading) {
        setLoading(true);
        const media = await createMedia({
          src: url,
          interview_id: interview.id,
          type: 'DOCUMENT',
        });
        setTime().then(() => {
          setLoading(false);
        });
        setMedias([...medias, media]);
        input.value = '';
        toast.success('ファイルアプロード成功', { autoClose: 2000 });
      }
    } catch (e) {
      console.log('ERROR', e);
      toast.error('ファイルアプロード失敗', { autoClose: 2000 });
    }
  };

  return (
    <div className="tooltip d-flex">
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <div className="btn-file">
          <input type="file" onChange={onFileUpload} />
          <FontAwesomeIcon
            icon="file-upload"
            size="lg"
            className="file-upload"
          />
        </div>
      )}
      {inline ? (
        <span className="dropdown-menu-text">ファイルアップロード</span>
      ) : (
        <span className="tooltiptext">ファイルアップロード</span>
      )}
    </div>
  );
};

export default UploadFiles;
