import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import interviewService from '../../services/interviewService';
import ChatList from './ChatList';
import MediaList from './MediaList';

export default function Interview() {
  const { id } = useParams();
  const [interview, setInterview] = useState();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const getInterview = async () => {
      const intv = await interviewService.getById(id);
      setInterview(intv);
      setMessages(intv?.conversation?.messages || []);
    };
    if (id) getInterview();
  }, [id]);

  if (!interview) {
    return <div className="text-center mt-5">Loading...</div>;
  }

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-5">セッションレポート</h2>
      <Card className="mb-2">
        <Card.Body>
          <Card.Title>Interview ID: {interview.id}</Card.Title>
          <Card.Text>
            開始日時:
            {format(new Date(interview.begin_at), 'yyyy年MM月dd日 hh:mm')}
          </Card.Text>
          <Card.Text>
            終了日時:
            {format(new Date(interview.finish_at), 'yyyy年MM月dd日 hh:mm')}
          </Card.Text>
          <Row>
            <Col sm={6}>
              <MediaList medias={interview.media || []} />
            </Col>
            <Col sm={6}>
              <ChatList messages={messages} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
