import React from 'react';

function SideBarImageTarget(props) {
  return (
    <div className="button-wrap" onClick={props.toggleSidebar}>
      {props.children}
    </div>
  );
}
export default SideBarImageTarget;
