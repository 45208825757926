import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';

import { useMediaContext } from '../../../hooks/useMedia';
import SideBarImageTarget from '../SideBarImageTarget';

function ButtonViewMedia({ inline = false }) {
  const media = useMediaContext();

  const toggleSidebar = (evt) => {
    evt.preventDefault();
    media.setIsSidebarOpen(!media.isSidebarOpen);
  };

  return (
    <div className="tooltip" role="button">
      <SideBarImageTarget toggleSidebar={toggleSidebar}>
        <FontAwesomeIcon
          icon={media.isSidebarOpen ? 'folder-open' : 'folder'}
          size="lg"
        />
      </SideBarImageTarget>
      {inline ? (
        <span className="dropdown-menu-text">ドキュメント閲覧</span>
      ) : (
        <span className="tooltiptext">ドキュメント閲覧</span>
      )}
    </div>
  );
}

export default memo(ButtonViewMedia);
