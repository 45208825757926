import './index.css';

import React from 'react';

function Sidebar(props) {
  const sidebar = {
    width: '245px',
    height: '80%',
    position: 'fixed',
    top: '0',
    color: '#f3f3f3',
    transition: '0.4s ease-out',
    backgroundColor: 'white',
    borderBottomLeftRadius: '5px',
  };

  const { isOpen, showOverlay } = props;
  const overlayOpen = isOpen ? 'overlay overlay--open' : 'overlay';
  const sidebarOpen = isOpen ? 'sidebar sidebar--open' : 'sidebar';
  const slideFrom =
    props.slideFrom === 'left' ? 'sidebar--left' : 'sidebar--right';
  const classNames = [sidebarOpen, slideFrom].join(' ');

  return (
    <>
      {showOverlay ? (
        <>
          <div className={overlayOpen} />
          <div className={classNames} ref={() => sidebar}>
            <div className="sidebar--menu">{props.children}</div>
          </div>
        </>
      ) : (
        <div className={classNames} ref={() => sidebar}>
          <div className="sidebar--menu">{props.children}</div>
        </div>
      )}
    </>
  );
}
export default Sidebar;
