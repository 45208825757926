import React from 'react';

import { useChannelContext } from '../../hooks/useChannel';
import LiveMessaging from '../liveMessaging';
import LocalVideoPlayer from '../videoScreen/localVideoPlayer';
import ButtonCopyUrl from './buttons/ButtonCopyUrl';
import ButtonLeaveChannel from './buttons/ButtonLeaveChannel';
import ButtonMore from './buttons/ButtonMore';
import ButtonRecord from './buttons/ButtonRecordScreen';
import ButtonShareScreen from './buttons/ButtonShareScreen';

function SidebarMeeting({ meeting }) {
  const { mediasoup } = useChannelContext();

  return (
    <div className="position-relative w-100 h-100">
      <div className="live-board-controller">
        <input
          id="url"
          style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
          value={window.location.href}
          readOnly
        />
        <ButtonCopyUrl />
        <>
          {mediasoup.hasActiveRemotePeers && (
            <>
              <ButtonRecord recordDefault={meeting?.is_record} />
            </>
          )}
        </>
        <div className="buttons">
          {mediasoup.hasActiveRemotePeers && (
            <>
              <ButtonShareScreen />
            </>
          )}
          <ButtonLeaveChannel />
        </div>
        <ButtonMore>
          {mediasoup.hasActiveRemotePeers && (
            <>
              <ButtonShareScreen inline={true} />
            </>
          )}
          <ButtonLeaveChannel inline={true} />
        </ButtonMore>
      </div>
      <div className="video-local-stream bg-dark">
        <LocalVideoPlayer />
      </div>
      <LiveMessaging />
    </div>
  );
}

export default SidebarMeeting;
