import React from 'react';

import { useChannelContext } from '../../hooks/useChannel';
import LiveMessaging from '../liveMessaging';
import FileUpload from '../upload/FileUpload';
import LocalVideoPlayer from '../videoScreen/localVideoPlayer';
import ButtonCopyUrl from './buttons/ButtonCopyUrl';
import ButtonLeaveChannel from './buttons/ButtonLeaveChannel';
import ButtonMore from './buttons/ButtonMore';
import ButtonRecord from './buttons/ButtonRecordScreen';
import ButtonShareScreen from './buttons/ButtonShareScreen';
import ButtonViewMedia from './buttons/ButtonViewMedia';
import SidebarImage from './SidebarImage';

function SidebarInterview({ interview }) {
  const { role, mediasoup } = useChannelContext();

  return (
    <div className="position-relative w-100 h-100">
      <div className="live-board-controller">
        <input
          id="url"
          style={{ opacity: 0, position: 'absolute', zIndex: '-1' }}
          value={window.location.href}
          readOnly
        />
        <ButtonCopyUrl />
        {role === 'doctor' && (
          <>
            <SidebarImage />
            {mediasoup.hasActiveRemotePeers && (
              <>
                <ButtonRecord />
              </>
            )}
            <div className="buttons">
              {mediasoup.hasActiveRemotePeers && (
                <>
                  <ButtonShareScreen />
                </>
              )}
              <ButtonViewMedia interview={interview} />
              <FileUpload interview={interview} />
              <ButtonLeaveChannel />
            </div>
            <ButtonMore>
              {mediasoup.hasActiveRemotePeers && (
                <>
                  <ButtonShareScreen inline={true} />
                </>
              )}
              <ButtonViewMedia interview={interview} inline={true} />
              <FileUpload interview={interview} inline={true} />
              <ButtonLeaveChannel inline={true} />
            </ButtonMore>
          </>
        )}
        {role !== 'doctor' && (
          <>
            <ButtonLeaveChannel />
          </>
        )}
      </div>
      <div className="video-local-stream bg-dark">
        <LocalVideoPlayer />
      </div>
      <LiveMessaging />
    </div>
  );
}

export default SidebarInterview;
