import '../components/channel/index.css';

import { isAfter, isBefore, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams, withRouter } from 'react-router-dom';

import Loader from '../components/common/Loader';
import SidebarInterview from '../components/sidebar/SidebarInterview';
import VideoScreen from '../components/videoScreen';
import ChannelProvider from '../hooks/useChannel';
import LiveChatProvider from '../hooks/useLiveChat';
import MediaProvider from '../hooks/useMedia';
import useQuery from '../hooks/useQuery';
import useUniqueId from '../hooks/useUniqueId';
import UserMediaProvider from '../hooks/useUserMedia';
import appService from '../services/appService';
import config from '../services/configService';
import interviewService from '../services/interviewService';
const CHANNEL_PREFIX = 'interview';

function InterviewPage() {
  const query = useQuery();
  const { interviewId, role } = useParams();
  const channelName = CHANNEL_PREFIX + interviewId;
  const uid = useUniqueId(channelName, role);
  const userId = uid.getId();

  const [interview, setInterview] = useState();

  useEffect(() => {
    const getInterview = async () => {
      const data = await interviewService.verifyAccessToken({
        id: interviewId,
        role,
        token: query.get('token'),
      });
      const { client_id } = data.schedule;
      appService.setClientMeta(client_id);
      setInterview(data);
    };

    getInterview();
  }, []);

  if (!interview) {
    return <Loader />;
  }

  const beginAt = interview.begin_at;
  const finishAt = interview.finish_at;

  const hasRequiredData = () => {
    return !isEmpty(interviewId);
  };

  const isInSchedule = () => {
    if (isEmpty(beginAt) || isEmpty(finishAt)) {
      return false;
    }

    if (config.get('validation.date') === false) {
      return true;
    }

    const now = new Date();
    return isBefore(parseISO(beginAt), now) && isAfter(parseISO(finishAt), now);
  };

  const isJoinable = () => {
    return hasRequiredData() && isInSchedule();
  };

  if (!isJoinable()) {
    return <Redirect to="/404" />;
  }

  const leaveChannel = async () => {
    if (!interview.id) return;
    await interviewService.notify(
      interview.id,
      interview.schedule.id,
      `${config.get('app.url')}/admin/interviews/${interview.id}`
    );
  };

  const channelProps = {
    channelName,
    leaveChannel,
    userId,
    role,
    conversation: interview.conversation,
  };

  return (
    <MediaProvider interviewId={interview.id}>
      <UserMediaProvider>
        <ChannelProvider {...channelProps}>
          <div className="video-board h-100">
            <VideoScreen />
            <LiveChatProvider>
              <div id="local-screen">
                <SidebarInterview interview={interview} />
              </div>
            </LiveChatProvider>
          </div>
        </ChannelProvider>
      </UserMediaProvider>
    </MediaProvider>
  );
}

export default withRouter(InterviewPage);
