import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Image, Spinner } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const types = {
  png: 'image',
  jpeg: 'image',
  jpg: 'image',
  gif: 'image',
  eps: 'image',
  al: 'image',
  indd: 'image',
  raw: 'image',
  pdf: 'file-pdf',
  tiff: 'image',
  psd: 'image',
  mp4: 'video',
  mp3: 'music',
  wmv: '',
  flv: '',
  avi: 'aviato',
  avchd: '',
  webm: '',
  mkv: '',
};

function Media({ media }) {
  const extension = (media.src || '').split('.').pop().toLowerCase();

  if (!types[extension]) {
    return (
      <>
        <a href={media.src}>{ReactHtmlParser(media.src)}</a>
      </>
    );
  }

  if (types[extension] === 'image') {
    return <Image src={media.src} className="item-file" />;
  }

  return (
    <>
      <div className="content-default">
        <FontAwesomeIcon icon={types[extension]} className="default" />
      </div>
      <a href={media.src}>{ReactHtmlParser(media.src)}</a>
    </>
  );
}

export default function MediaItem({ media, onDelete }) {
  const [isLoading, setLoading] = useState(false);

  const handleDelete = (evt, id) => {
    setLoading(true);
    onDelete(id);
  };

  return (
    <div id="item-media">
      <div className="medias" key={media.id}>
        <Media media={media} />
        <br />
        {isLoading ? (
          <Button variant="danger">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          </Button>
        ) : (
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(null, media.id)}>
            削除
          </Button>
        )}
      </div>
    </div>
  );
}
