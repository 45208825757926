import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { useMediaContext } from '../../hooks/useMedia';
import Media from '../media';
import Sidebar from './Sidebar';

function SidebarImage() {
  const slideFrom = {
    right: 'right',
    left: 'left',
  };

  const media = useMediaContext();
  const [, setSidebarWidth] = useState('');
  const [selectedTab, setSelectedTab] = useState('');

  const handleClose = (evt) => {
    evt.preventDefault();
    media.setIsSidebarOpen(false);
  };

  const getWidth = (width) => {
    setSidebarWidth(width);
  };

  // const handleShowImage = () => {
  //     setSidebarOpen(true);
  // };

  const handleActiveTab = (key) => {
    setSelectedTab(key);
  };

  return (
    <Sidebar
      isOpen={media.isSidebarOpen}
      showOverlay
      slideFrom={slideFrom.left}
      getWidth={getWidth}>
      <Tabs activeKey={selectedTab} onSelect={handleActiveTab}>
        <Tab eventKey="image" title="ドキュメント" />
        <div
          title={
            <FontAwesomeIcon
              icon="times"
              onClick={handleClose}
              className="icon-close"
              size="lg"
            />
          }
        />
      </Tabs>
      <div className="scroller">
        <Media />
      </div>
    </Sidebar>
  );
}
export default SidebarImage;
